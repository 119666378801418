body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.star {
  outline: none;
  border-color: unset;
  margin-left: -2px;
}
.star span{
  margin-right: 7px;
}
input {
  caret-color: #c42d31 !important;
}
span {
  cursor: default;
}
textarea {
  caret-color: #c42d31 !important;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}
::-webkit-input-placeholder { /* Edge */
  color: rgba(60, 60, 67, 0.6);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(60, 60, 67, 0.6);
}
::placeholder {
  color: rgba(60, 60, 67, 0.6);
}
.input-comment {
  background: transparent !important;
  background-color: transparent !important;
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
}
@font-face {
  font-family: "DancingScriptRegular";
  src: local("DancingScriptRegular"),
   url("./fonts/DancingScript-Regular.ttf") format("truetype");
  font-weight: normal;
  }
@font-face {
  font-family: "NewYorkSmallRegular";
  src: local("NewYorkSmallRegular"),
  url("./fonts/NewYorkSmall-Regular.otf") format("truetype");
  font-weight: normal;
  }
  @font-face {
    font-family: "NewYorkSmallBold";
    src: local("NewYorkSmallBold"),
    url("./fonts/NewYorkSmall-Bold.otf") format("truetype");
    font-weight: bold;
    }
  @font-face {
  font-family: "SFProTextRegular";
  src: local("SFProTextRegular"),
  url("./fonts/SFProTextRegular.ttf") format("truetype");
  font-weight: normal;
  }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
